import * as React from "react"

import PickerScreen from '../../../../components/pickerScreen'


const Picker = ({ data }) => (
  <PickerScreen
    question="How do you want to work?"
    options={[
      {
        label: "I prefer to install a desktop software",
        link: "/app/graphic-design/slides/operating-system/"
      },
      {
        label: "I prefer to work online in a web browser",
        link: "/app/graphic-design/slides/online-tool/"
      }
  ]}/>
)

export default Picker;


